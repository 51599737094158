import { mdiHomeOutline } from '@mdi/js'

export default [
  {
    title: 'Home',
    icon: mdiHomeOutline,
    to: 'dashboard-crm'
    // children: [
    //   {
    //     title: 'Home',
    //     to: 'dashboard-crm',
    //   },
      // {
      //   title: 'Analytics',
      //   to: 'dashboard-analytics',
      // },
      // {
      //   title: 'eCommerce',
      //   to: 'dashboard-eCommerce',
      // },
    // ],
  },
]
